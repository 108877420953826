<template>
  <div class="awardTable_page">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <div class="table_box" :style="{ 'opacity': loading ? '1' : '0' }">
      <div class="lotteryKind5" ref="imageDom" v-if="lotteryKind === '5'">
        <h4 class="title">排列五长条</h4>
        <img class="headTailImage" v-if="headImage" :src="headImage" alt="">
        <table valign="center">
          <tr>
            <td>期号</td>
            <td>值</td>
            <td>A</td>
            <td>B</td>
            <td>C</td>
            <td>D</td>
            <td>E</td>
          </tr>
          <tr v-for="(item, index) in list" :key="index" :class="[item.bgColor ? 'bgColor': '', item.groupStartLabel == '1' ? 'groupStartLabel' : '']" v-show="!item.flag">
            <td>{{item.periodText}} <br> <span class="datemmdd">{{item.lotteryDate | setDateMMdd}} </span></td>
            <td>{{item.lotteryVu}} <br> <span class="week">{{item.lotteryDate | getWeek}} </span></td>
            <td>{{item.num1}}</td>
            <td>{{item.num2}}</td>
            <td>{{item.num3}}</td>
            <td>{{item.num4}}</td>
            <td>{{item.num5}}</td>
          </tr>
        </table>
        <img class="headTailImage" v-if="tailImage" :src="tailImage" alt="">
      </div>
      <div class="lotteryKind7" ref="imageDom" v-else-if="lotteryKind === '7'">
        <h4 class="title">七彩星长条</h4>
        <img class="headTailImage" v-if="headImage" :src="headImage" alt="">
        <table>
          <tr>
            <td>期号</td>
            <td>值</td>
            <td>A</td>
            <td>B</td>
            <td>C</td>
            <td>D</td>
            <td>E</td>
            <td>F</td>
            <td>G</td>
          </tr>
          <tr v-for="(item, index) in list" :key="index" :class="[item.bgColor ? 'bgColor': '', item.groupStartLabel == '1' ? 'groupStartLabel' : '']" v-show="!item.flag">
            <td>{{item.periodText}} <br> <span class="datemmdd">{{item.lotteryDate | setDateMMdd}} </span></td>
            <td>{{item.lotteryVu}} <br> <span class="week">{{item.lotteryDate | getWeek}} </span></td>
            <td>{{item.num1}}</td>
            <td>{{item.num2}}</td>
            <td>{{item.num3}}</td>
            <td>{{item.num4}}</td>
            <td>{{item.num5}}</td>
            <td>{{item.num6}}</td>
            <td>{{item.num7}}</td>
          </tr>
        </table>
        <img class="headTailImage" v-if="tailImage" :src="tailImage" alt="">
      </div>
      <p class="tipTxt">长按图片“分享”或“保存”图片</p>
    </div>
    <br>
    <div class="photo_box">
      <img class="photo" :src="imgUrl" alt="">
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import Moment from 'moment'
import html2canvas from "html2canvas";
import isWeixin from '@/utils/utils.js'
export default {
  components: {
    vHeader
  },
  data() {
    return {
      title: this.$route.meta.title,
      lotteryKind: '',
      list: [],
      imgUrl: '',
      headImage: '',
      tailImage: '',
      showHeader:true
    }
  },
  filters: {
    setDateMMdd(vle) {
      if (vle) { return Moment(vle).format('MM月DD') }
      return '';
    },
    getWeek(date) {
      let week = Moment(date).day()
      switch (week) {
        case 1:
          return '星期一'
        case 2:
          return '星期二'
        case 3:
          return '星期三'
        case 4:
          return '星期四'
        case 5:
          return '星期五'
        case 6:
          return '星期六'
        case 0:
          return '星期日'
      }
    }
  },

  computed: {
    loading() {
      if (this.list.length && this.headImage && this.tailImage) {
        return true
      }
      return false
    }
  },

  async created() {
    this.lotteryKind = this.$store.state.common.lotteryKind
    this.getHeadImage()
    this.getTailImage()
    this.onLoad()
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
  },
  methods: {
    async onLoad() {
      const res = await this.$HTTP.post(this, 'API_LOTTERY_LISTS_SPACE', { lotteryKind: this.lotteryKind, pageNo: '1', pageSize: '30', appendSpaceNum: '0' }, {}, true)
      this.list = res.data || []
      this.list = this.list.reverse()
      let start = 0
      // 丢弃标志
      let flag = false
      this.list.forEach((item, index) => {
        if (item.groupStartLabel == '1') {
          start = !start ? 1 : 0
          flag = true
        }
        if (start) {
          this.list[index].bgColor = true
        }
        if (!flag) {
          item.flag = true
        }
      })
      this.html2canvas()
    },
    html2canvas() {
      setTimeout(() => {
        if (this.loading) {
          // dpi: 300 分辨率 useCORS: true 解决图片跨域问题
          html2canvas(this.$refs.imageDom, { dpi: 300, useCORS: true }).then(canvas => {
            // 转成图片，生成图片地址
            this.imgUrl = canvas.toDataURL("image/png"); //可将 canvas 转为 base64 格式
          });
        } else {
          this.html2canvas()
        }
      }, 300)
    },
    // 长条的头部图片API
    async getHeadImage() {
      const res = await this.$HTTP.get(this, this.$API['API_LOTTERY_HEAD_IMAGE'], { lottery_kind: this.lotteryKind }) || {}
      this.headImage = res.data
    },
    // 长条的尾部图片API
    async getTailImage() {
      const res = await this.$HTTP.get(this, this.$API['API_LOTTERY_TAIL_IMAGE'], { lottery_kind: this.lotteryKind }) || {}
      this.tailImage = res.data
    }
  }
};
</script>

<style lang="less">
.awardTable_page {
  .title {
    margin: 0;
    text-align: center;
    font-size: 20px;
    line-height: 2.4em;
  }
  .table_box {
    .tipTxt {
      text-align: center;
      font-size: 15px;
      opacity: 0.6;
    }
  }
  .lotteryKind5 {
    padding-bottom: 15px;
    table {
      border-collapse: collapse;
      border: 1px solid #816bda;
      width: 92%;
      margin: 0 4%;
      tr {
        td {
          border: 1px solid #816bda;
          font-size: 34px;
          font-weight: bold;
          color: #360f10;
          text-align: center;
          width: 13.33%;
          font-weight: 700;
          line-height: 1em;
          padding: 6px 0;
        }
        td:nth-of-type(1) {
          font-size: 14px;
          width: 20%;
          .datemmdd {
            font-weight: normal;
            font-size: 12px;
            line-height: 1em;
          }
        }
        td:nth-of-type(2) {
          font-size: 18px;
          border-right-width: 2px !important;
          .week {
            font-weight: normal;
            font-size: 12px;
            line-height: 1em;
          }
        }
      }
      tr:nth-of-type(1) {
        td {
          color: white;
          background-color: #816bda;
          border-right: 1px solid white;
        }
        td:last-child {
          border-right: 1px solid #816bda;
        }
        td:nth-of-type(1),
        td:nth-of-type(2) {
          font-size: 24px;
          font-weight: inherit;
          padding: 4px 0;
        }
      }
      tr.bgColor {
        td {
          background-color: #ece9f4;
        }
      }
      tr.groupStartLabel {
        td {
          border-top: #816bda 2px solid;
        }
      }
    }
  }
  .lotteryKind7 {
    padding-bottom: 15px;
    table {
      border-collapse: collapse;
      border: 1px solid #598a2f;
      width: 92%;
      margin: 0 4%;
      tr {
        td {
          border: 1px solid #598a2f;
          font-size: 32px;
          font-weight: bold;
          color: #598a2f;
          text-align: center;
          width: 10.5%;
          font-weight: 700;
          line-height: 1em;
          padding: 6px 0;
        }
        td:nth-of-type(1) {
          font-size: 14px;
          width: 17%;
          .datemmdd {
            font-weight: normal;
            font-size: 12px;
          }
        }
        td:nth-of-type(2) {
          width: 12%;
          font-size: 16px;
          border-right-width: 2px !important;
          .week {
            font-weight: normal;
            font-size: 12px;
          }
        }
        td:nth-of-type(7),
        td:nth-of-type(8) {
          font-size: 24px;
          width: 9%;
        }
        td:nth-of-type(9) {
          font-size: 18px;
          width: 9%;
        }
      }
      tr:nth-of-type(1) {
        td {
          color: white;
          background-color: #598a2f;
          border-right: 1px solid white;
        }
        td:last-child {
          border-right: 1px solid #598a2f;
        }
        td:nth-of-type(1),
        td:nth-of-type(2) {
          font-size: 24px;
          font-weight: inherit;
          padding: 4px 0;
        }
        td:nth-of-type(9) {
          font-size: 20px;
        }
      }
      tr.bgColor {
        td {
          background-color: #e8eddf;
        }
      }
      tr.groupStartLabel {
        td {
          border-top: #598a2f 2px solid;
        }
      }
    }
  }
  .headTailImage {
    width: 92%;
    margin: 0 4%;
    display: block;
    margin-bottom: 1px;
  }
  .photo_box {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 1;
    opacity: 0;
    .photo {
      display: block;
      width: 100%;
    }
  }
}
</style>